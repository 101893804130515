
 @font-face {
  font-family: 'MazzardBold';
  src: url('../assets/font/MazzardM-Bold.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MazzardLight';
  src: url('../assets/font/MazzardM-Light.woff2') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MazzardMedium';
  src: url('../assets/font/MazzardM-Medium.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MazzardRegular';
  src: url('../assets/font/MazzardM-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MazzardSemiBold';
  src: url('../assets/font/MazzardM-SemiBold.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
}